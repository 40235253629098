import React from 'react';
import { DisplayProps } from '../types/common';

export function RunStatus(props: DisplayProps) {
  const { display } = props;

  if (!display) {
    return null;
  }

  return (
    <div className="text-center">
      <div className="spinner-border" id="bfGenSpinner" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
