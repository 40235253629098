import { SECONDS_IN_MIN } from '../constants';

export function getBearerTokenOpts(auth0Token: string) {
  return {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };
}

export async function fetchWithAuth0Token(
  url: string,
  auth0Token: string,
  opts: RequestInit = {},
) {
  return await fetch(
    url,
    Object.assign({}, opts, getBearerTokenOpts(auth0Token)),
  );
}

export function calculateIfMfaIsAboutToExpire(
  initialLoggedInDate: Date,
  mfaExpireDuration: number,
  now = new Date(),
  howEarlyToWarn = SECONDS_IN_MIN,
) {
  const timePassed = (now.valueOf() - initialLoggedInDate.valueOf()) / 1000;
  return timePassed > mfaExpireDuration - howEarlyToWarn;
}
