import React from 'react';
import { DisplayProps } from '../types/common';

export function BankfileRunError(props: DisplayProps) {
  if (!props.display) {
    return null;
  }
  return (
    <div>
      <div
        className="alert alert-danger alert-dismissible fade show mt-4"
        role="alert"
        id="bfGenErrorAlert"
      >
        <strong>Error!</strong> Request Failed!
        <br /> Refresh the page or check your internet connection.
        <button
          type="button"
          className="btn-close"
          id="bfGenErrorAlertCloseButton"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
  );
}
