import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import SignOut from '../components/SignOut';
import { BACKEND_URL } from '../constants';
import { EnumRequestCommand, IDescibeLogs, IGetLogs } from './adminTypes';
import { fetchWithAuth0Token } from '../utils/auth0';

const DEFAULT_STREAMS: IDescibeLogs = { logStreams: [] };
const DEFAULT_STREAM_EVENTS: IGetLogs = { events: [] };
const urlPrefix = `${BACKEND_URL}/cloudWatchLogs`;

async function runCloudWatchCommandDescribeLogStreams(
  limit: string = '20',
  auth0Token: string,
): Promise<IDescibeLogs> {
  if (!limit) {
    console.error('Malformed Input');
  }
  try {
    const streams: { data: { logStreams: [] } } = await (
      await fetchWithAuth0Token(
        `${urlPrefix}?command=${EnumRequestCommand.DescribeLogStreams}&limit=${limit}`,
        auth0Token,
      )
    ).json();
    console.log('CloudWatch Streams:', streams);
    return { logStreams: streams.data?.logStreams };
  } catch (err) {
    console.error('CloudWatch Streams Error:', err);
    return Object.assign({}, DEFAULT_STREAMS);
  }
}

async function runCloudWatchCommandGetLogs(
  logStreamName: string,
  limit: string = '20',
  auth0Token: string,
): Promise<IGetLogs> {
  if (!logStreamName) {
    console.error('Malformed Input');
  }
  try {
    const streamEvents: { data: IGetLogs } = await (
      await fetchWithAuth0Token(
        `${urlPrefix}?command=${EnumRequestCommand.GetLogEvents}&limit=${limit}&logStreamName=${logStreamName}`,
        auth0Token,
      )
    ).json();
    console.log('CloudWatch Stream Events:', streamEvents);
    return streamEvents.data;
  } catch (err) {
    console.error('CloudWatch Stream Events Error:', err);
    return Object.assign({}, DEFAULT_STREAM_EVENTS);
  }
}

function Admin() {
  const [auth0Token, setAuth0Token] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const [streams, setStreams] = useState(Object.assign({}, DEFAULT_STREAMS));
  const [streamEvents, setStreamEvents] = useState(
    Object.assign({}, DEFAULT_STREAM_EVENTS),
  );

  /* Effect hooks */
  // run only on once
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (auth0Token === '') {
      return;
    }
    (async () => {
      const limit = '15';
      const streams = await runCloudWatchCommandDescribeLogStreams(
        limit,
        auth0Token,
      );
      setStreams(streams);
    })();
  }, [auth0Token]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="navbar-brand" href="#">
            Safeguard Global
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" id="homePage" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Admin
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/admin/e2e">
                  AdminE2E
                </a>
              </li>
            </ul>
            <SignOut />
          </div>
        </div>
      </nav>
      <div className="container-fluid p-4 mt-2">
        <h4>Bank File ECS Tasks Logs</h4>
        {streams.logStreams.length ? (
          <table className="table table-sm table-striped form-text">
            <thead>
              <tr>
                <th>Log Stream</th>
                <th>Last Event Time</th>
              </tr>
            </thead>
            <tbody>
              {streams.logStreams.map(item => {
                return (
                  <tr key={item.logStreamName}>
                    <td>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        className="link-primary"
                        href="#"
                        onClick={async () => {
                          const limit = '20';
                          const streamEvents =
                            await runCloudWatchCommandGetLogs(
                              item.logStreamName,
                              limit,
                              auth0Token,
                            );
                          setStreamEvents(streamEvents);
                        }}
                      >
                        {item.logStreamName.includes('scheduled')
                          ? 'Scheduled Task: Cache Clients Info'
                          : 'Bankfile Generation Task'}
                      </a>
                    </td>
                    <td>{new Date(item.lastEventTimestamp).toString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
        {streamEvents.events.length === 0 &&
        streamEvents.$metadata &&
        streamEvents.$metadata.httpStatusCode === 200 ? (
          <p>CloudWatchLogs are found but there were no events to display.</p>
        ) : null}
        {streamEvents.events.length ? (
          <div>
            {streamEvents.events.map((item, index) => {
              return (
                <div className="form-text" key={index}>
                  {item.message}
                </div>
              );
            })}
          </div>
        ) : null}
        <hr />
      </div>
    </div>
  );
}

export default Admin;
