import React from 'react';

export function RunButton(props: any) {
  const {
    selectedClient,
    selectedPayroll,
    selectedPeriod,
    selectedPayrollType,
    isRunInProgress,
    handleBtnClickBankFileRun,
  } = props;

  return (
    <button
      className="btn btn-sm"
      id="runBfButton"
      onClick={async () => {
        await handleBtnClickBankFileRun();
      }}
      disabled={
        !(
          !isRunInProgress &&
          selectedClient &&
          selectedPayroll &&
          selectedPeriod &&
          selectedPayrollType
        )
      }
    >
      Display Bank Data
    </button>
  );
}
