import React from 'react';
import { ISummaryInfoGeneral } from '../types/common';
import { ISelectedPeriod } from '../App';
import { EmployeesExpectedSection } from './EmployeesExpectedSection';
import { IEmployeesExpectedSection } from '@safeguardglobal/gmp-bffs-shared-types';

interface Props {
  selectedPeriod: ISelectedPeriod;
  data: ISummaryInfoGeneral;
  clientsets: IEmployeesExpectedSection[];
}

export function AggregatedReport(props: Props) {
  const { selectedPeriod, data, clientsets } = props;
  let eeClientsets: JSX.Element[] = [];
  if (clientsets.length > 1) {
    eeClientsets.push(<EmployeesExpectedSection key="PAYROLL" data={data} />);
  }
  clientsets.forEach(function (clientset: IEmployeesExpectedSection) {
    eeClientsets.push(
      <EmployeesExpectedSection
        key={clientset.clientsetName}
        data={clientset}
        currency={clientset.currency}
      />,
    );
  });

  return (
    <div>
      <div className="section-header">
        Unity Payroll Aggregated Bank Data Report:
        <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>
          {selectedPeriod.period_name}
          <small>
            {',  '}
            {selectedPeriod.start_date} to {selectedPeriod.end_date}
          </small>
        </span>
      </div>
      <table className="table table-bordered table-striped table-hover table-sm small">
        <tbody>
          <tr>
            <td>Pay Date</td>
            <td>{data.payDate}</td>
          </tr>
          <tr>
            <td>Bank Transfer Date</td>
            <td>{data.bankTransferDate}</td>
          </tr>
          <tr>
            <td>No of EEs in a Confirmed State</td>
            <td>{data.confirmedEEsCount}</td>
          </tr>
          <tr>
            <td>No of EEs in an Unconfirmed State</td>
            <td>{data.unconfirmedEEsCount}</td>
          </tr>
          <tr>
            <td>No of EEs with active EPA</td>
            <td>{data.activeEEsCount}</td>
          </tr>
          {eeClientsets}
          <tr className="table-section-title">
            <td colSpan={2}>Employees Not Expected in the Bank File</td>
          </tr>
          <tr>
            <td>No of EEs with Other Payment Methods</td>
            <td>{data.otherPaymentEEsCount}</td>
          </tr>
          <tr>
            <td>Other Payment Methods Net Pay Amount</td>
            <td>{data.otherEEsAmount}</td>
          </tr>
          <tr>
            <td>No of EEs with Zero Net Pay</td>
            <td>{data.zeroPaymentEEsCount}</td>
          </tr>
          <tr>
            <td>No of EEs with Negative Net Pay</td>
            <td>{data.negativePaymentEEsCount}</td>
          </tr>
          <tr>
            <td>Negative Net Pay Amount</td>
            <td>{data.negativeEEsAmount}</td>
          </tr>
          <tr>
            <td>Total Net Pay amount for EEs with active EPA</td>
            <td>{data.activeEEsAmount}</td>
          </tr>
          <tr>
            <td>Total Net Pay amount for EEs in a confirmed state</td>
            <td>{data.confirmedEEsAmount}</td>
          </tr>
          <tr>
            <td>Total Net Pay amount for EEs ın an unconfirmed state</td>
            <td>{data.unconfirmedEEsAmount}</td>
          </tr>
          <tr className="table-section-title">
            <td colSpan={2}>Variances</td>
          </tr>
          <tr>
            <td>
              Total Net Pay Variance{' '}
              <img
                src="icon-info.png"
                width={20}
                height={20}
                data-mdb-toggle="tooltip"
                data-mdb-placement="top"
                title="Total Net Pay amount for EEs with Active EPA - Total Net Pay amount Expected in the Bank File"
                alt=""
              />
            </td>
            <td>{data.totalAmountVariance}</td>
          </tr>
          <tr>
            <td>
              Employee Count Variance{' '}
              <img
                src="icon-info.png"
                width={20}
                height={20}
                data-mdb-toggle="tooltip"
                data-mdb-placement="top"
                title="No of EEs with Active EPA - No of EEs Expected in the Bank File"
                alt=""
              />
            </td>
            <td>{data.employeesVariance}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
