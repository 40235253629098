import React, { useState } from 'react';
import Select from 'react-select';

type SelectProps = {
  options: any;
  value: string;
  isLoading: boolean;
  isDisabled?: boolean;
  onChange: (value: any) => void;
  placeholder: string;
  id: string;
};

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? '1px solid blue' : '1px solid gray',
    borderRadius: '8px',
    backgroundColor: state.isDisabled ? '#f0f0f2' : 'white',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid blue',
    },
  }),
};

const SelectComponent: React.FC<SelectProps> = ({
  options,
  value,
  isLoading = false,
  isDisabled = false,
  placeholder,
  onChange,
  id,
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  const handleMenuOpen = () => {
    setInputValue('');
  };

  const menuPortalTarget = document.getElementById('root');

  return (
    <Select
      key={value}
      options={options}
      value={
        options.find(
          (item: { value: string; label: string }) => item.value === value,
        ) || ''
      }
      isLoading={isLoading}
      isDisabled={isDisabled}
      styles={customStyles}
      onInputChange={handleInputChange}
      onMenuOpen={handleMenuOpen}
      onChange={onChange}
      inputValue={inputValue}
      placeholder={placeholder}
      isSearchable
      maxMenuHeight={450}
      menuPlacement="auto"
      menuPortalTarget={menuPortalTarget}
      id={id}
      inputId={id}
      formatOptionLabel={({ label, id: testId }) => (
        <div id={testId}>{label}</div>
      )}
    />
  );
};

export default SelectComponent;
