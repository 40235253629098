import React from 'react';
import SelectComponent from './Select';

export function PayrollSelect(props: any) {
  const {
    selectedClient,
    selectedPayroll,
    setSelectedPayroll,
    payrollsMappings,
    isRunInProgress,
    loading,
  } = props;

  const payrollIds =
    selectedClient && payrollsMappings?.[selectedClient]?.payrolls
      ? Object.keys(payrollsMappings[selectedClient].payrolls)
      : [];

  const payrollOptions = payrollIds
    .map(payrollId => {
      const payroll = payrollsMappings[selectedClient]?.payrolls[payrollId];
      return { value: payrollId, label: payroll.payroll_name, id: payrollId };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const isDisabled = loading || payrollOptions.length === 0 || isRunInProgress;

  const handleChange = (payroll: { value: string; label: string }) => {
    setSelectedPayroll(payroll.value);
  };

  return (
    <div className="mb-3">
      <label className="form-label" htmlFor="payrollSelect">
        Payroll:
      </label>
      <SelectComponent
        id="payrollSelect"
        isLoading={loading}
        isDisabled={isDisabled}
        value={selectedPayroll}
        onChange={handleChange}
        placeholder="--select payroll--"
        options={payrollOptions}
      />
    </div>
  );
}
