import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import '../index.css';
import App from '../App';
import Admin from '../admin/Admin';
import E2E from '../admin/E2E';

const ProtectedApp = withAuthenticationRequired(App);
const ProtectedAdmin = withAuthenticationRequired(Admin);
const ProtectedE2E = withAuthenticationRequired(E2E);

export default function ProtectedRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedApp />} />
      <Route path="/admin" element={<ProtectedAdmin />} />
      <Route path="/admin/e2e" element={<ProtectedE2E />} />
    </Routes>
  );
}
