import React from 'react';
import SelectComponent from './Select';

export function PeriodSelect(props: any) {
  const {
    selectedClient,
    selectedPayroll,
    selectedPeriod,
    setSelectedPeriod,
    payrollsMappings,
    currentPeriodInd,
    isRunInProgress,
    loading,
  } = props;

  const periodsObject =
    selectedClient &&
    payrollsMappings?.[selectedClient]?.payrolls?.[
      selectedPayroll
    ]?.periods_info?.map((period: any, ind: any) => {
      return ind === currentPeriodInd
        ? {
            label: `${period.payroll_year} ${period.period_no} (current)`,
            value: period,
            id: period.start_date,
          }
        : {
            label: `${period.payroll_year} ${period.period_no}`,
            value: period,
            id: period.start_date,
          };
    });

  const periodOptions = periodsObject || [];
  const isDisabled = loading || periodOptions?.length === 0 || isRunInProgress;

  const handleChange = (period: { value: string; label: string }) => {
    setSelectedPeriod(period.value);
  };

  return (
    <div className="mb-3">
      <label className="form-label" htmlFor="periodSelect">
        Period:
      </label>
      <SelectComponent
        id="periodSelect"
        isLoading={loading}
        isDisabled={isDisabled}
        value={selectedPeriod}
        onChange={handleChange}
        placeholder="--select period--"
        options={periodOptions}
      />
      {Object.keys(selectedPeriod).length !== 0 && (
        <span>
          <label className="form-text text-primary">
            {selectedPeriod.period_name}
          </label>
          <label className="form-text">
            {selectedPeriod.start_date === '' ? (
              <></>
            ) : (
              <small>
                {',  '}
                {selectedPeriod.start_date} to {selectedPeriod.end_date}
              </small>
            )}
          </label>
        </span>
      )}
    </div>
  );
}
