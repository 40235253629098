import React from 'react';
import { IFblSummaryInputCbdListItem } from '@safeguardglobal/gmp-bffs-shared-types';

interface ICompanyDataReportProps {
  cbdList: IFblSummaryInputCbdListItem[];
}

export function CompanyDataReport(props: ICompanyDataReportProps) {
  const tags = props.cbdList.filter((tag: any) => tag.value);

  return (
    <div id="companyDataReport">
      <div className="section-header">Company Bank Account</div>
      <table
        className="table table-bordered table-striped table-hover table-sm small"
        style={{ width: '100%' }}
      >
        <thead>
          <tr className="table-header">
            <th>Field Label</th>
            <th>Value</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag: any) => (
            <tr key={tag.id}>
              <td>{tag.name}</td>
              <td>{tag.value}</td>
              <td>{tag.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
