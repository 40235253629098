import React, { useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import { isValidClientFormat } from '../utils/formatCheckers';
import { ActionMeta } from 'react-select';

interface Option {
  readonly label: string;
  readonly value: string;
}

function createOption(label: string) {
  const uppercase = label.toUpperCase();
  return {
    label: uppercase,
    value: uppercase,
  };
}

export function ClientSelect(props: any) {
  const {
    isRunInProgress,
    invalidTenantError,
    setInvalidTenantError,
    setSelectedClient,
    validClientsGetter,
  } = props;

  const handleChange = (
    client: { value: string; label: string },
    actionMeta: ActionMeta<Option>,
  ) => {
    const { action } = actionMeta;
    if (action === 'clear') {
      setValue(undefined);
      setSelectedClient('');
      return;
    }
    setInvalidTenantError('');
    const clientUppercase = createOption(client.value);
    setValue(clientUppercase);
    setSelectedClient(clientUppercase?.value || '');
  };

  const [value, setValue] = useState<Option | null>();

  const invalidErrorStyle = {
    color: 'red',
  };

  const validClients = validClientsGetter();
  const validClientsOptions = validClients.map((client: string) =>
    createOption(client),
  );

  return (
    <div className="mb-3">
      <label className="form-label" htmlFor="clientSelect">
        Unity Payroll Tenant ID:
      </label>
      <CreatableSelect
        id="clientSelectCreatable"
        isClearable
        isDisabled={isRunInProgress}
        isValidNewOption={function (inputValue) {
          return isValidClientFormat(inputValue.toUpperCase());
        }}
        onChange={handleChange}
        options={validClientsOptions}
        formatCreateLabel={function (inputValue) {
          return `Select "${inputValue}"`;
        }}
        placeholder="--select client--"
        value={value}
      />
      {invalidTenantError && (
        <p style={invalidErrorStyle}>{invalidTenantError}</p>
      )}
    </div>
  );
}
