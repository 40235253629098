import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function SignOut() {
  const { isAuthenticated, logout } = useAuth0();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <button
      className="btn btn-light btn-sm"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Sign Out
    </button>
  );
}
