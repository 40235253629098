import { BACKEND_URL } from '../constants';
import { fetchWithAuth0Token } from '../utils/auth0';
import {
  IFblSummary,
  TPeriodListResponseItem,
} from '@safeguardglobal/gmp-bffs-shared-types';

interface IErrorResponse {
  error: string;
}

interface IParametersForBfEcsTask {
  client: string;
  payrollId: string;
  periodName: string;
  periodYear: string;
  periodNo: string;
  startDate: string;
  endDate: string;
}

type TFetchPayrollItem = [number, string];

interface IFetchPayroll {
  data: TFetchPayrollItem[];
}

type TFetchPayrollResponse = IFetchPayroll | IErrorResponse;

export async function fetchPeriods(
  client: string,
  payrollId: string,
  auth0Token: string,
) {
  const urlParams = new URLSearchParams([
    ...Object.entries({ client, payrollId }),
  ]).toString();
  console.log('Fetch Periods');
  const response = await (
    await fetchWithAuth0Token(`${BACKEND_URL}/periods?${urlParams}`, auth0Token)
  ).json();
  return response.data as TPeriodListResponseItem[];
}

export async function fetchPayrolls(
  client: string,
  auth0Token: string,
): Promise<TFetchPayrollItem[] | IErrorResponse> {
  const urlParams = new URLSearchParams([
    ...Object.entries({ client }),
  ]).toString();
  console.log('Fetch Payrolls');
  const results = (await (
    await fetchWithAuth0Token(
      `${BACKEND_URL}/payrolls?${urlParams}`,
      auth0Token,
    )
  ).json()) as TFetchPayrollResponse;
  if ('error' in results) {
    console.error(results.error);
    return results as IErrorResponse;
  }
  console.log(results.data.length, 'payrolls loaded');
  return results.data as TFetchPayrollItem[];
}

export async function invokeSummaryReport(
  selectedInputs: IParametersForBfEcsTask,
  auth0Token: string,
): Promise<IFblSummary> {
  const urlParams = new URLSearchParams([
    ...Object.entries(selectedInputs),
  ]).toString();
  console.log(selectedInputs);
  console.log('Start Lambda');
  const response = await (
    await fetchWithAuth0Token(
      `${BACKEND_URL}/summaryReport?${urlParams}`,
      auth0Token,
    )
  ).json();
  return response.data;
}
