import React from 'react';
import { IPaymentInfoBaseItem } from '../types/common';
import {
  BootstrapTable,
  convertObjectForTableHelper,
  DEFAULT_BOOTSTRAP_TABLE_OPTIONS,
  IBootstrapTableProps,
} from './ExclusionsTable';

interface IExclusionsReportProps {
  negativePayEEs: IPaymentInfoBaseItem[];
  otherPaymentsEEs: IPaymentInfoBaseItem[];
  zeroPayEEs: IPaymentInfoBaseItem[];
}

const DEFAULTS_OTHER_METHODS: IBootstrapTableProps = {
  ...DEFAULT_BOOTSTRAP_TABLE_OPTIONS,
  id: 'other-method-employees',
  title: 'Other Methods Employees',
  colNames: [
    'Employee Number',
    'Surname',
    'Forename',
    'Amount',
    'Payment Method',
  ],
  rowOrder: ['empNo', 'lastName', 'firstName', 'amount', 'paymentMethod'],
  data: [],
};

const DEFAULTS_NEGATIVE_PAY: IBootstrapTableProps = {
  ...DEFAULT_BOOTSTRAP_TABLE_OPTIONS,
  id: 'negative-net-employees',
  title: 'Negative Net Employees',
  colNames: ['Employee Number', 'Surname', 'Forename', 'Net Pay Amount'],
  rowOrder: ['empNo', 'lastName', 'firstName', 'amount'],
  data: [],
};

const DEFAULTS_ZERO_NET_EMPLOYEES: IBootstrapTableProps = {
  ...DEFAULT_BOOTSTRAP_TABLE_OPTIONS,
  id: 'zero-net-employees',
  title: 'Zero Net Employees',
  colNames: ['Employee Number', 'Surname', 'Forename', 'Net Pay Amount'],
  rowOrder: ['empNo', 'lastName', 'firstName', 'amount'],
  data: [],
};

export function ExclusionsReport(props: IExclusionsReportProps) {
  const { negativePayEEs, otherPaymentsEEs, zeroPayEEs } = props;
  const negativePay = {
    ...DEFAULTS_NEGATIVE_PAY,
    data: convertObjectForTableHelper(negativePayEEs),
  };
  const otherMethods = {
    ...DEFAULTS_OTHER_METHODS,
    data: convertObjectForTableHelper(otherPaymentsEEs),
  };
  const zeroNet = {
    ...DEFAULTS_ZERO_NET_EMPLOYEES,
    data: convertObjectForTableHelper(zeroPayEEs),
  };
  return (
    <>
      <BootstrapTable {...otherMethods} />
      <BootstrapTable {...negativePay} />
      <BootstrapTable {...zeroNet} />
    </>
  );
}
