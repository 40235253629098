import React from 'react';
import { IEmployeesExpectedSection } from '../types/common';

interface Props {
  data: IEmployeesExpectedSection;
  currency?: string;
}

export function EmployeesExpectedSection(props: Props) {
  const { data } = props;
  const clientsetNameDisplayed = data.clientsetName
    ? `- ${data.clientsetName}`
    : '(accumulated)';
  const strFile = data.clientsetName ? 'File' : 'Files';

  return (
    <>
      <tr className="table-section-title">
        <td colSpan={2}>
          Employees Expected in the Bank {strFile} {clientsetNameDisplayed}
        </td>
      </tr>
      <tr>
        <td>No of EEs Expected in the Bank {strFile}</td>
        <td>{data.bankPaymentEEsCount}</td>
      </tr>
      <tr>
        <td>Total Net Pay Amount Expected in the Bank {strFile}</td>
        <td>{data.bankEEsAmount}</td>
      </tr>
      {/* This is hidden for now, but may be useful in the future 
      {currency ? (
        <tr>
          <td>Bank File Currency</td>
          <td>{currency}</td>
        </tr>
      ) : null} */}
    </>
  );
}
