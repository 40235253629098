export enum EnumRequestCommand {
  DescribeLogStreams = 'describeLogStreams',
  GetLogEvents = 'getLogEvents',
}

export interface ILogItem {
  message: string;
}

export interface IDescribeItem {
  logStreamName: string;
  lastEventTimestamp: string;
}

export interface IDescibeLogs {
  logStreams: IDescribeItem[];
}

export interface IGetLogs {
  $metadata?: {
    httpStatusCode: number;
  };
  events: ILogItem[];
}
