import React from 'react';
import { ISelectedPeriod } from '../App';
import { AggregatedReport } from './AggregatedReport';
import { CompanyDataReport } from './CompanyDataReport';
import { ExclusionsReport } from './ExclusionsReport';
import { IFblSummary } from '@safeguardglobal/gmp-bffs-shared-types';

interface Props {
  selectedPeriod: ISelectedPeriod;
  summary: IFblSummary;
}

export function ReportsFacade(props: Props) {
  const { selectedPeriod, summary } = props;
  if (!summary) {
    return null;
  }

  const { info, input } = summary;

  return (
    <div id="aggregatedReport">
      <AggregatedReport
        selectedPeriod={selectedPeriod}
        data={info.general}
        clientsets={info.clientsets}
      />
      <br />
      <CompanyDataReport cbdList={input.cbdList} />
      <br />
      <ExclusionsReport
        negativePayEEs={info.negativePayEEs}
        otherPaymentsEEs={info.otherPaymentsEEs}
        zeroPayEEs={info.zeroPayEEs}
      />
    </div>
  );
}
