import React, { useCallback, useState, useEffect } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import userflow from 'userflow.js';
import * as LambdaHelper from './aws/lambdaHelper';
import { fetchPayrolls, fetchPeriods } from './aws/lambdaHelper';
import { Navbar } from './components/Navbar';
import { ClientSelect } from './main/ClientSelect';
import { PayrollSelect } from './main/PayrollSelect';
import { PeriodSelect } from './main/PeriodSelect';
import { RunButton } from './main/RunButton';
import { RunStatus } from './main/RunStatus';
// import { useInterval } from './utils/useInterval';
// import { SECONDS_IN_MIN } from './constants';
// import { calculateIfMfaIsAboutToExpire } from './utils/auth0';
// import { MfaWarning } from './components/MfaWarning';
import { BankfileRunError } from './components/BankFileRunError';
import { ReportsFacade } from './reports/ReportsFacade';
import { IFblSummary } from '@safeguardglobal/gmp-bffs-shared-types';
import { isAlphanumeric } from './utils/formatCheckers';
import { userflowToken } from './constants';

export interface ISelectedPeriod {
  period_name: string;
  period_no?: string;
  start_date: string;
  end_date: string;
  payroll_year?: string;
}

const DEFAULT_SELECTED_PERIOD: ISelectedPeriod = {
  period_name: '',
  start_date: '',
  end_date: '',
};

interface IPeriodInfo {
  start_date: string;
  end_date: string;
  payroll_year?: string;
  period_no?: string;
  period_name?: string;
}

interface IPayroll {
  periods_info: IPeriodInfo[];
  payroll_name?: string;
}

type IPayrolls = Record<string, IPayroll>;
interface IClient {
  payrolls: IPayrolls;
}
type IPayrollMappings = { [key: string]: IClient };
const DEFAULT_PAYROLL_MAPPINGS: IPayrollMappings = {};

// const initialLoggedInDate = new Date();

const validClientsKey = 'clientList';

const getValidClients = () =>
  JSON.parse(localStorage.getItem(validClientsKey)) ?? [];

const addToValidClients = (client: string) => {
  const validClients = getValidClients();
  if (client && !validClients.includes(client)) {
    validClients.push(client);
    localStorage.setItem(validClientsKey, JSON.stringify(validClients));
  }
};

function App() {
  const [auth0Token, setAuth0Token] = useState('');
  const { user, getAccessTokenSilently } = useAuth0();
  // Extracting sub and deriving user ID
  const subArray = user?.sub?.split('|');
  const userId = subArray[subArray.length - 1];

  userflow.init(userflowToken);
  userflow.identify(userId, {
    name: user.given_name,
    email: user.email,
    signed_up_at: user.updated_at,
  });

  // const { use_mfa } = user['https://safeguardglobal.com/app_metadata'];
  // const mfaExpireDuration = use_mfa
  //   ? 10 * SECONDS_IN_MIN
  //   : 10 * SECONDS_IN_MIN * SECONDS_IN_MIN;
  // State variables
  // const [isMfaAboutToExpire, setIsMfaAboutToExpire] = useState(false);
  // const isMfaAboutToExpireRef = useRef(isMfaAboutToExpire);
  const [selectedClient, setSelectedClient] = useState('');

  const [payrollsMappings, setPayrollsMappings] = useState(
    Object.assign({}, DEFAULT_PAYROLL_MAPPINGS),
  );
  const [selectedPayroll, setSelectedPayroll] = useState('');

  const [currentPeriodInd, setCurrentPeriodIndex] = useState(-1);
  const [selectedPeriod, setSelectedPeriod] = useState(
    Object.assign({}, DEFAULT_SELECTED_PERIOD),
  );
  const selectedPayrollType = 'Payroll';
  const [isRunInProgress, setIsRunInProgress] = useState(false);
  const [payrollFetching, setPayrollFetching] = useState(false);
  const [periodFetching, setPeriodFetching] = useState(false);
  const [bankFileRunError, setBankFileRunError] = useState(false);
  const [summary, setSummary] = useState<IFblSummary | null>(null);
  const [invalidTenantError, setInvalidTenantError] = useState('');

  const stringifiedPayrollMappings = JSON.stringify(payrollsMappings, null, 0);

  // Find and set the current period index of the selected payroll
  const findAndSetCurrentPeriodInd = useCallback(
    function ({ selectedClient, selectedPayroll }: any) {
      const currentDate = new Date().toJSON().slice(0, 10);
      if (!payrollsMappings[selectedClient]) {
        return;
      }
      payrollsMappings[selectedClient]['payrolls'][selectedPayroll][
        'periods_info'
      ].forEach(function (period: IPeriodInfo, ind: number) {
        const startDate = period.start_date;
        const endDate = period.end_date;
        if (currentDate >= startDate && currentDate <= endDate) {
          setCurrentPeriodIndex(ind);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedPayrollMappings],
  );

  async function handleBtnClickBankFileRun() {
    setIsRunInProgress(true);
    setBankFileRunError(false);
    const selectedInputs = {
      client: selectedClient,
      payrollId: selectedPayroll,
      periodName: `${selectedPeriod.payroll_year} ${selectedPeriod.period_no}`,
      periodYear: selectedPeriod.payroll_year,
      periodNo: selectedPeriod.period_no,
      startDate: selectedPeriod.start_date,
      endDate: selectedPeriod.end_date,
    };

    try {
      const summaryJson = await LambdaHelper.invokeSummaryReport(
        selectedInputs,
        auth0Token,
      );
      setSummary(summaryJson);
    } catch (err) {
      setBankFileRunError(true);
    }
    setIsRunInProgress(false);
  }

  /*
  useInterval(
    () => {
      if (isMfaAboutToExpireRef.current) {
        return;
      }
      if (
        calculateIfMfaIsAboutToExpire(initialLoggedInDate, mfaExpireDuration)
      ) {
        setIsMfaAboutToExpire(true);
        isMfaAboutToExpireRef.current = true;
      }
    },
    isMfaAboutToExpireRef.current ? null : 20 * 1000,
  );
  */

  // run only once
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  // When the client changes
  useEffect(() => {
    setSelectedPayroll('');
    setSelectedPeriod(Object.assign({}, DEFAULT_SELECTED_PERIOD));
    // Access the cache only once for each client
    const getPayrolls = async () => {
      try {
        const data = await fetchPayrolls(selectedClient, auth0Token);
        if ('error' in data) {
          throw new Error(data.error);
        }
        const newMappings: IClient = { payrolls: {} };
        console.log('data', data);
        for (let i = 0; i < data.length; i++) {
          const [payrollId, payroll_name] = data[i];
          newMappings['payrolls'][payrollId.toString()] = {
            payroll_name,
            periods_info: [],
          };
        }

        setPayrollsMappings({
          ...payrollsMappings,
          [selectedClient]: newMappings,
        });
        if (data.length === 0) {
          throw new Error('User has no access');
        }
        addToValidClients(selectedClient);
      } catch (e) {
        console.error(e);
        setInvalidTenantError(e.message);
      } finally {
        setPayrollFetching(false);
      }
    };

    if (
      selectedClient &&
      isAlphanumeric(selectedClient) &&
      !payrollsMappings[selectedClient]
    ) {
      setPayrollFetching(true);
      getPayrolls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0Token, selectedClient]);

  // When the payroll changes
  useEffect(() => {
    setSelectedPeriod(Object.assign({}, DEFAULT_SELECTED_PERIOD));
    setCurrentPeriodIndex(-1);
    // Access the cache only once for each payroll
    if (selectedClient && selectedPayroll && payrollsMappings[selectedClient]) {
      const currentClientDetails = payrollsMappings[selectedClient];
      if (
        !currentClientDetails.payrolls[selectedPayroll]?.periods_info?.length
      ) {
        setPeriodFetching(true);
        const getPeriods = async () => {
          const data = await fetchPeriods(
            selectedClient,
            selectedPayroll,
            auth0Token,
          );
          const periods: IPeriodInfo[] = [];
          for (let i = 0; i < data.length; i++) {
            const [payroll_year, period_no, start_date, end_date, period_name] =
              data[i];
            periods.push({
              start_date,
              end_date,
              payroll_year: payroll_year.toString(),
              period_no: period_no.toString(),
              period_name,
            });
          }

          currentClientDetails.payrolls[selectedPayroll].periods_info = periods;
          setPayrollsMappings(prevMappings => ({
            ...prevMappings,
            [selectedClient]: currentClientDetails,
          }));
        };
        getPeriods()
          .catch(console.error)
          .finally(() => setPeriodFetching(false));
      } else {
        findAndSetCurrentPeriodInd({ selectedClient, selectedPayroll });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth0Token,
    selectedPayroll,
    findAndSetCurrentPeriodInd,
    stringifiedPayrollMappings,
    selectedClient,
  ]);

  // When the period changes
  useEffect(() => {
    setSummary(null);
  }, [auth0Token, selectedPeriod]);

  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <br />
        <h4 className="text-center">Bank Data App</h4>
        <div className="container-fluid col-8">
          <div className="container-fluid bg-secondary bg-opacity-10 p-5 small">
            <ClientSelect
              setSelectedClient={setSelectedClient}
              isRunInProgress={isRunInProgress}
              validClientsGetter={getValidClients}
              invalidTenantError={invalidTenantError}
              setInvalidTenantError={setInvalidTenantError}
            />
            <PayrollSelect
              selectedClient={selectedClient}
              selectedPayroll={selectedPayroll}
              setSelectedPayroll={setSelectedPayroll}
              payrollsMappings={payrollsMappings}
              isRunInProgress={isRunInProgress}
              loading={payrollFetching}
            />
            <PeriodSelect
              selectedClient={selectedClient}
              selectedPayroll={selectedPayroll}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              payrollsMappings={payrollsMappings}
              currentPeriodInd={currentPeriodInd}
              isRunInProgress={isRunInProgress}
              loading={periodFetching}
            />
            <RunButton
              selectedClient={selectedClient}
              selectedPayroll={selectedPayroll}
              selectedPeriod={selectedPeriod}
              selectedPayrollType={selectedPayrollType}
              isRunInProgress={isRunInProgress}
              handleBtnClickBankFileRun={handleBtnClickBankFileRun}
            />
            <RunStatus display={isRunInProgress} />
            <BankfileRunError display={bankFileRunError} />
          </div>
          <div>
            {summary ? (
              <ReportsFacade
                selectedPeriod={selectedPeriod}
                summary={summary}
              />
            ) : null}
          </div>
          {/* <MfaWarning display={isMfaAboutToExpire} /> */}
        </div>
      </div>
    </div>
  );
}

export default App;
