import React from 'react';

interface IBootstrapTableDefaultProps {
  isRowIndexAdded: boolean;
  classExtra?: string;
  title?: string;
}

export interface IBootstrapTableProps extends IBootstrapTableDefaultProps {
  colNames: string[];
  id: string;
  rowOrder: string[];
  data: any[];
}

export const DEFAULT_BOOTSTRAP_TABLE_OPTIONS: IBootstrapTableDefaultProps = {
  isRowIndexAdded: true,
  classExtra: 'table-striped table-bordered table-sm small',
};

export function convertObjectForTableHelper(data: any[]) {
  return data.map(function (item: any) {
    return {
      ...item,
    };
  });
}

export function BootstrapTable(props: IBootstrapTableProps) {
  const {
    id,
    title,
    colNames,
    rowOrder,
    isRowIndexAdded,
    data,
    classExtra = '',
  } = props;
  if (data.length === 0) {
    return null;
  }
  const className = `table ${classExtra}`;
  return (
    <>
      {title ? <div className="section-header">{title}</div> : null}
      <table id={id} className={className}>
        <thead>
          <tr className="table-header">
            {isRowIndexAdded ? <th className="index">#</th> : null}
            {colNames.map((columnName, idx) => (
              <th key={`${id}-${columnName}-${idx}`}>{columnName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => (
            <tr key={`${id}-${idx}`}>
              {isRowIndexAdded ? (
                <td key={`${id}-${idx}-index`} className="index">
                  {idx + 1}
                </td>
              ) : null}
              {rowOrder.map((rowKey, rowIdx) => (
                <td key={`${id}-${idx}-${rowIdx}`}>{item[rowKey]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
